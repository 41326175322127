import React, { Children } from "react";
import { useMemo, useEffect, useState, useRef, StrictMode } from "react";
import config from "../config/config";
import {
  MainContainer,
  Sidebar,
  ConversationList,
  Avatar,
  ChatContainer,
  ConversationHeader,
  MessageGroup,
  Message,
  MessageList,
  MessageInput,
  Button,
  Search,
  ExpansionPanel,
  MessageSeparator,
} from "@chatscope/chat-ui-kit-react";
import { faSignOutAlt, faCopy } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  parseISO,
  isSameDay,
} from "date-fns";
import Cookies from "universal-cookie";
import { useChat } from "@chatscope/use-chat";
import axios from "axios";
import { User } from "@chatscope/use-chat";
import Spinner from "./spinner";
import deleteIcon from "../assets/deleteIcon.png";
export const Chat = ({ user }: { user: User }) => {
  const useInterval = (callback: () => void, delay: number | null) => {
    // Creating a ref to store the latest callback
    const savedCallback = useRef<() => void>();
    // Remember the latest callback when it changes
    useEffect(() => {
      savedCallback.current = callback;
    }, [callback]);
    useEffect(() => {
      function tick() {
        if (savedCallback.current) {
          savedCallback.current();
        }
      }
      if (delay !== null) {
        const id = setInterval(tick, delay);
        return () => clearInterval(id);
      }
    }, [delay]);
  };

  interface tmpMsg {
    keyId: number;
    sender: string;
    receiver: string;
    recvTm: string;
    sms: string;
    portNum: number;
    hasGet: number;
    displayNumber: string;
    isRead: number;
    isDeleted?: number;
    record_id: string;
    company: string;
    fullname: string;
    estimated_revenue: string;
    fname: string;
    lname: string;
    email: string;
    idxGoipId: number;
  }
  interface tmpMsgCollection {
    [sender: string]: tmpMsg[];
  }
  interface MesgCountByUser {
    [key: string]: number;
  }

  const cookies = new Cookies();
  const id = cookies.get("id");
  const [initialRows, setInitialRows] = useState<tmpMsg[]>([]);
  const [selectedCustomerData, setSelectedCustomerData] =
    useState<tmpMsg | null>(null);

  const [showSpinner, setShowSpinner] = useState(false);
  const [arrBySender, setArrBySender] = useState<tmpMsgCollection>({});
  const [nowMessages, setNowMessages] = useState<tmpMsg[]>([]);
  const [lastSender, setLastSender] = useState<string | null>(null);
  const [stateRightSide, setStateRightSide] = useState<string>("hidden");
  const [customerData, setCustomerData] = useState<any>(null);
  const { activeConversation, currentMessage, setCurrentMessage, sendTyping } =
    useChat();
  const [showCopiedMessage, setShowCopiedMessage] = useState(false);

  const messageListRef = useRef<HTMLDivElement>(null);

  // //  to scroll to bottom
  useEffect(() => {
    if (messageListRef.current) {
      const element = messageListRef.current;
      element.scrollTop = element.scrollHeight;
    }
  }, [nowMessages]);

  //helper method to seperate the messages by day
  const shouldShowDateSeparator = (msg: tmpMsg[], index: number) => {
    if (index === msg.length - 1) return true; // Always show for the first message
    const prevMessageDate = parseISO(nowMessages[index + 1].recvTm);
    const currentMessageDate = parseISO(nowMessages[index].recvTm);
    const isNewDay = !isSameDay(prevMessageDate, currentMessageDate);
    return isNewDay;
  };

  //function to display the time correctly checks if its in the week or month and renders it appropiately
  const changeTimeByMyBrowser = (strTime: any) => {
    const browserTimezone = new Date().getTimezoneOffset(); // Get the browser's timezone offset
    const timestamp = new Date(strTime);
    timestamp.setTime(timestamp.getTime() + 4 * 60 * 60 * 1000); // Adjust the time for the browser's timezone

    const now = new Date();
    const oneWeekAgo = new Date();
    oneWeekAgo.setDate(now.getDate() - 7);

    let formattedTimestamp;

    if (
      timestamp.getFullYear() === now.getFullYear() &&
      timestamp.getMonth() === now.getMonth() &&
      timestamp.getDate() === now.getDate()
    ) {
      // Today
      formattedTimestamp = new Intl.DateTimeFormat("en", {
        hour: "2-digit",
        minute: "2-digit",
      }).format(timestamp);
    } else if (
      timestamp.getFullYear() === now.getFullYear() &&
      timestamp.getMonth() === now.getMonth() &&
      timestamp.getDate() === now.getDate() - 1
    ) {
      // Yesterday
      formattedTimestamp = "Yesterday";
    } else {
      // Older than yesterday
      formattedTimestamp = `${String(timestamp.getMonth() + 1).padStart(
        2,
        "0"
      )}/${String(timestamp.getDate()).padStart(
        2,
        "0"
      )}/${timestamp.getFullYear()}`;
    }

    return formattedTimestamp;
  };

  //function to always display the messages time
  const formattedMsgTime = (strTime: any) => {
    const browserTimezone = new Date().getTimezoneOffset(); // Get the browser's timezone offset
    const timestamp = new Date(strTime);
    timestamp.setTime(timestamp.getTime() + 4 * 60 * 60 * 1000); // Adjust the time for the browser's timezone

    let formattedTimestamp;

    formattedTimestamp = new Intl.DateTimeFormat("en", {
      hour: "2-digit",
      minute: "2-digit",
    }).format(timestamp);

    return formattedTimestamp;
  };

  const getCustomerData = async (phoneNumber: string) => {
    try {
      const response = await axios.post(
        config.serverUrl + "/chat/getCustomerData",
        {
          phoneNumber: phoneNumber,
          keyId: id,
        }
      );
      setCustomerData(response.data);
    } catch (error) {
      setCustomerData(null); //return an empty value
      console.error("Error fetching customer data:", error);
    }
  };

  //conditional logic of when to render the date seperator
  const renderDateSeparator = (messages: any, index: any) => {
    if (shouldShowDateSeparator(messages, index)) {
      return (
        // <MessageSeparator
        //   content={changeTimeByMyBrowser(messages[index].recvTm)}
        // />

        <MessageSeparator children = {
          changeTimeByMyBrowser(messages[index].recvTm)
        }/>
      );
    }
    return null;
  };

  const rearrangeBySender = (arr: tmpMsg[]): tmpMsgCollection => {
    let result: tmpMsgCollection = {};
    arr.forEach((item: tmpMsg) => {
      const displayNumber = formatPhoneNumber(item.sender);
      const newItem = { ...item, displayNumber }; // Add displayNumber to the message object
      if (!result[item.sender]) {
        result[item.sender] = [];
      }
      result[item.sender].push(newItem);
    });
    return result;
  };

  const getUsersAndLastMessages = async () => {
    try {
      const response = await axios.post(
        config.serverUrl + "/chat/getUsersAndLastMessages",
        { id: cookies.get("id") }
      );
      const res = response.data.messages;
      return res;
    } catch (error) {
      console.error("Error fetching test messages:", error);
      return null;
    }
  };

  const showSpinnerLimitSeconds = (seconds: any) => {
    setShowSpinner(true);
    setTimeout(() => {
      setShowSpinner(false);
    }, seconds * 1000);
  };

  const initializeMessages = async () => {
    showSpinnerLimitSeconds(7);
    try {
      let data = await getUsersAndLastMessages();
      setShowSpinner(false);
      setInitialRows(data);
      localStorage.setItem("totalLength", data.length);
      const tmp = await rearrangeBySender(data);
      setMsgCountByUserToLocalStorage(tmp);
      setArrBySender(tmp);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const setMsgCountByUserToLocalStorage = (obj: tmpMsgCollection) => {
    let jsondata: MesgCountByUser = {};
    for (const key in obj) {
      jsondata[key] = obj[key].length;
    }
    let res = JSON.stringify(jsondata);
    localStorage.setItem("mesgCountByUser", res);
  };

  const orderByRecentSender = (obj: { [key: string]: tmpMsg[] }) => {
    let tmpArrShow: { key: string; recvTm: string }[] = [];
    Object.keys(obj).forEach((key) => {
      tmpArrShow.push({ key, recvTm: obj[key][0]["recvTm"] });
    });
    tmpArrShow.sort(
      (a, b) => new Date(b.recvTm).getTime() - new Date(a.recvTm).getTime()
    );
    let res: string[] = [];
    tmpArrShow.forEach((item) => {
      res.push(item.key);
    });
    return res;
  };

  const handleChange = (value: string) => {
    setCurrentMessage(value);
    if (activeConversation) {
      sendTyping({
        conversationId: activeConversation?.id,
        isTyping: true,
        userId: user.id,
        content: value, // Note! Most often you don't want to send what the user types, as this can violate his privacy!
        throttle: true,
      });
    }
  };

  const onSelectLeft = (value: any) => {
    setNowMessages(value);
    const selectedNumber = getSelectedUserNumber(value);
    setSelectedCustomerData(value[0]); // Assuming the first message contains the customer data
    markConversationRead(selectedNumber, 1);
  };

  const getSelectedUserNumber = (value: any) => {
    let lastRow: tmpMsg = value[0];
    let displayNumber: string =
      lastRow.hasGet != -1 ? lastRow.sender : lastRow.receiver;
    console.log(displayNumber + "SELECTED!!!!!!!!!!!!");
    return displayNumber;
  };

  //function to send messages
  const sendMsg = async (lastRow: tmpMsg) => {
    if (lastRow) {
      const data = {
        sms: lastRow.sms,
        to: lastRow.receiver,
        machineID: lastRow.idxGoipId, // Ensure this is sent
        from: lastRow.portNum,
        tid: "1",
      };
      try {
        const URL = config.serverUrl;
        const response = await axios.post(`${URL}/sendMsg`, data);
        console.log("API TEST:", response.data);
      } catch (error) {
        console.error("Error in sendMsg:", error);
      }
    }

    const response = await axios.post(config.serverUrl + "/chat/sendMsg", {
      id,
      lastRow,
    });
    console.log("saved message response:", response.data.result);
    if (response.data.result[0].keyId > 0) return true;
    else return false;
  };

   const updateArrBySender = async (text: string) => {
    let lastRow: tmpMsg = nowMessages[nowMessages.length - 1];
    let newRow: tmpMsg | null = null;
    const now = new Date();
    const year = now.getUTCFullYear();
    const month = String(now.getUTCMonth() + 1).padStart(2, "0");
    const day = String(now.getUTCDate()).padStart(2, "0");
    const hours = String(now.getUTCHours()).padStart(2, "0");
    const minutes = String(now.getUTCMinutes()).padStart(2, "0");
    const seconds = String(now.getUTCSeconds()).padStart(2, "0");
    const currentTimeString = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;

    if (lastRow?.hasGet != -1) {
        newRow = {
            keyId: 1,
            sender: lastRow.receiver,
            receiver: lastRow.sender,
            recvTm: currentTimeString,
            portNum: lastRow.portNum,
            sms: text,
            hasGet: -1,
            displayNumber: formatPhoneNumber(lastRow.displayNumber),
            isRead: 0,
            isDeleted: lastRow.isDeleted,
            record_id: lastRow.record_id,
            company: lastRow.company,
            fullname: lastRow.fullname,
            estimated_revenue: lastRow.estimated_revenue,
            fname: lastRow.fname,
            lname: lastRow.lname,
            email: lastRow.email,
            idxGoipId: lastRow.idxGoipId,
        };
    } else if (lastRow?.hasGet === -1) {
        newRow = {
            keyId: 1,
            sender: lastRow.sender,
            receiver: lastRow.receiver,
            recvTm: currentTimeString,
            portNum: lastRow.portNum,
            sms: text,
            hasGet: -1,
            displayNumber: formatPhoneNumber(lastRow.displayNumber),
            isRead: 0,
            isDeleted: lastRow.isDeleted,
            record_id: lastRow.record_id,
            company: lastRow.company,
            fullname: lastRow.fullname,
            estimated_revenue: lastRow.estimated_revenue,
            fname: lastRow.fname,
            lname: lastRow.lname,
            email: lastRow.email,
            idxGoipId: lastRow.idxGoipId,
        };
    }

    if (newRow) {
        const updatedNowMessages = [newRow, ...nowMessages];
        let updatedInitRows = [newRow, ...initialRows];
        setInitialRows(updatedInitRows);

        setArrBySender((prevArrBySender) => {
            let updatedArrBySender = { ...prevArrBySender };
            if (newRow!.hasGet === 0) {
                updatedArrBySender[newRow!.sender] = updatedNowMessages;
            } else if (newRow!.hasGet === -1) {
                updatedArrBySender[newRow!.receiver] = updatedNowMessages;
            }
            return updatedArrBySender;
        });
        setNowMessages((prevMessages) => {
            return [...updatedNowMessages];
        });

        let isSaved = await sendMsg(newRow);
        console.log("Message sent:", isSaved);
    }
    setShowSpinner(false);
};

  //handles the sending of the messages
  const handleSend = (text: string) => {
    updateArrBySender(text);
    setCurrentMessage("");
  };
  //Function to handle logout button
  const handleLogout = () => {
    console.log("Logout clicked");
    cookies.remove("id");
    cookies.remove("token");
    window.location.reload();
  };

  const UpdateMsgs = async () => {
    let data = await getUsersAndLastMessages();
    let totalLength = parseInt(localStorage.getItem("totalLength")!);
    console.log(
      "All msg cnt : original msg count = ",
      data.length,
      ":",
      totalLength + " : " + new Date().toLocaleString()
    );
    if (data.length > totalLength) {
      setInitialRows(data);
      const tmp = await rearrangeBySender(data);
      localStorage.setItem("totalLength", data.length);
      setArrBySender(tmp);
    }
  };

  const a = useInterval(async () => await UpdateMsgs(), 20000);
  const [intervalState, setIntervalState] = useState(a);

  useEffect(() => {
    initializeMessages();
  }, []);

  // Function to format a user's phone number
  const formatPhoneNumber = (phoneNumber: string) => {
    const cleaned = ("" + phoneNumber).replace(/\D/g, "");
    const match = cleaned.match(/^(\d{1})(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return `+${match[1]} (${match[2]}) ${match[3]}-${match[4]}`;
    }
    return phoneNumber;
  };

  //function to always display senders number:

  // Highlights and copies phone numbers
  const highlightText = (text: any, element: any) => {
    const dataTransfer = new DataTransfer();
    dataTransfer.setData("text", text);
    const textData = dataTransfer.getData("text");
    navigator.clipboard.writeText(textData);

    const range = document.createRange();
    range.selectNodeContents(element);
    const selection = window.getSelection();
    if (selection != null) {
      selection.removeAllRanges();
      selection.addRange(range);
      // Add these two lines to remove the highlight immediately
      setTimeout(() => {
        selection.removeAllRanges();
      }, 0);
      setShowCopiedMessage(true);

      // Hide the copied message and show the original icon after 2 seconds
      setTimeout(() => {
        setShowCopiedMessage(false);
      }, 2000);
    }
  };

  const filterJsonBySender = (
    msgCollection: tmpMsgCollection,
    sender: string
  ): tmpMsgCollection => {
    const filteredCollection: tmpMsgCollection = {};
    for (const key in msgCollection) {
      if (key !== sender) {
        filteredCollection[key] = msgCollection[key];
      }
    }
    return filteredCollection;
  };

  const SetReadJsonBySender = (
    msgCollection: tmpMsgCollection,
    sender: string
  ): tmpMsgCollection => {
    for (const key in msgCollection[sender]) {
      msgCollection[sender][key].isRead = 1;
    }
    // console.log("SetReadJsonBySender", msgCollection);
    return msgCollection;
  };

  const markConversationDeleted = async (
    phoneNumber: string,
    isDeleted: number
  ) => {
    let tmpArrBySender: tmpMsgCollection = arrBySender;
    tmpArrBySender = filterJsonBySender(tmpArrBySender, phoneNumber);
    setArrBySender(tmpArrBySender);

    try {
      await axios.post(
        `${config.serverUrl}/conversation/markConversationDeleted`,
        {
          phoneNumber,
          isDeleted,
        }
      );
    } catch (error) {
      console.error("Error marking conversation as deleted:", error);
    }
  };

  const markConversationRead = async (phoneNumber: string, isRead: number) => {
    let tmpArrBySender: tmpMsgCollection = arrBySender;
    tmpArrBySender = SetReadJsonBySender(tmpArrBySender, phoneNumber);
    setArrBySender(tmpArrBySender);

    try {
      await axios.post(
        `${config.serverUrl}/conversation/markConversationRead`,
        {
          phoneNumber,
          isRead,
        }
      );
    } catch (error) {
      console.error("Error marking conversation as deleted:", error);
    }
  };

  // Function to handle delete button click
  const handleDeleteConversation = (phoneNumber: string) => {
    // eslint-disable-next-line no-restricted-globals
    if (confirm("Are you sure you want to delete this conversation?")) {
      markConversationDeleted(phoneNumber, 1);
    }
  };

  return (
    <MainContainer responsive>
      <Sidebar position="left" scrollable>
        {" "}
        {/*sidebar*/}
        <ConversationHeader>
          <Avatar src={user.avatar} />
          <ConversationHeader.Content>
            {user.username}
          </ConversationHeader.Content>
          <ConversationHeader.Actions>
            <Button
              labelPosition="right"
              icon={<FontAwesomeIcon icon={faSignOutAlt} />}
              onClick={handleLogout}
            />
          </ConversationHeader.Actions>
        </ConversationHeader>
        <Search placeholder="Search..." />
        <ConversationList>
          {orderByRecentSender(arrBySender).length > 0 &&
            orderByRecentSender(arrBySender).map((sender: string) => {
              const senderMessages: tmpMsg[] = arrBySender[sender];

              const latestMessage: tmpMsg =
                senderMessages[senderMessages.length - 1]; // Use the latest message
              const senderData: tmpMsg = senderMessages[0]; // Assuming there is at least one message for each sender

              if (senderData.isDeleted) {
                return null; // Skip deleted conversations
              }
              const [avatar, name] = [undefined, latestMessage.displayNumber];

              const isActive =
                nowMessages.length > 0 &&
                nowMessages[0].displayNumber === latestMessage.displayNumber;
              const displayName =
                senderData.fullname || senderData.displayNumber; // Use number if name is null

              const unreadClass =
                senderData.isRead === 0 && senderData.hasGet === 0
                  ? "cs-conversation__message--unread"
                  : "";
              const unreadTimeClass =
                senderData.isRead === 0 && senderData.hasGet === 0
                  ? "cs-conversation__time--unread"
                  : "";

              return (
                !senderData.isDeleted && (
                  <div
                    key={sender}
                    className={`cs-conversation ${
                      isActive ? "cs-conversation--active" : ""
                    }`}
                    style={{ position: "relative" }}
                    onClick={() => onSelectLeft(senderMessages)}
                  >
                    {avatar}
                    <div className="cs-conversation__content">
                      <div className="cs-conversation__details">
                        <div
                          className={`cs-conversation__name ${
                            !senderData.fullname
                              ? "cs-conversation__name--number"
                              : ""
                          }`}
                        >
                          {displayName}
                        </div>
                        <div
                          className={`cs-conversation__time ${unreadTimeClass}`}
                        >
                          {changeTimeByMyBrowser(senderData.recvTm)}
                        </div>
                      </div>
                      {senderData.fullname && (
                        <div
                          className={`cs-conversation__number ${unreadClass}`}
                        >
                          {senderData.displayNumber}
                        </div>
                      )}
                      <div
                        className={`cs-conversation__message ${unreadClass}`}
                      >
                        {senderData.sms}
                      </div>
                    </div>
                    {senderData.isRead === 0 && senderData.hasGet === 0 && (
                      <div className="cs-conversation__unread-dot"></div>
                    )}
                    <div className="cs-conversation__actions">
                      <img
                        src={deleteIcon}
                        alt="Delete"
                        className="delete_img"
                        onClick={(e) => {
                          e.stopPropagation(); // Prevent triggering the onClick of the Conversation
                          handleDeleteConversation(senderData.sender);
                        }}
                      />
                    </div>
                  </div>
                )
              );
            })}
        </ConversationList>
      </Sidebar>

      <ChatContainer>
        {nowMessages.length > 0 && (
          <ConversationHeader>
            <ConversationHeader.Content
              info={nowMessages[nowMessages.length - 1].company}
              userName={nowMessages[nowMessages.length - 1].displayNumber}
            />
            <ConversationHeader.Actions>
              <Button
                title="Copy"
                icon={
                  showCopiedMessage ? (
                    <span style={{ color: "gray", fontSize: "14px" }}>
                      {" "}
                      Copied!
                    </span>
                  ) : (
                    <FontAwesomeIcon icon={faCopy} />
                  )
                }
                style={{ cursor: "pointer", color: "black" }}
                onClick={(e) =>
                  highlightText(
                    nowMessages[nowMessages.length - 1].displayNumber,
                    e.currentTarget
                  )
                }
              />
            </ConversationHeader.Actions>
          </ConversationHeader>
        )}
        <MessageList ref={messageListRef}>
          {nowMessages.map((msg, index) => (
            <div key={index} className="message-block">
              {renderDateSeparator(nowMessages, index)}
              <MessageGroup
                direction={msg.hasGet === -1 ? "outgoing" : "incoming"}
              >
                <MessageGroup.Messages>
                  <Message
                    model={{
                      message: msg.sms,
                      sentTime: formattedMsgTime(msg.recvTm), //format(parseISO(msg.recvTm), "p"),
                      sender: "---",
                      position: "last",
                      direction: msg.hasGet === -1 ? "outgoing" : "incoming",
                    }}
                  >
                    <Message.Footer>
                      {formattedMsgTime(msg.recvTm)}
                    </Message.Footer>
                  </Message>
                </MessageGroup.Messages>
              </MessageGroup>
            </div>
          ))}
        </MessageList>

        <MessageInput
          value={currentMessage}
          onChange={handleChange}
          onSend={handleSend}
          disabled={!(nowMessages.length > 0)}
          attachButton={false}
          placeholder="Type here..."
        />
      </ChatContainer>
      <Sidebar position="right">
        <ExpansionPanel open title="Customer Information">
          {selectedCustomerData ? (
            <>
              <p>Record ID: {selectedCustomerData.record_id}</p>
              <p>Company: {selectedCustomerData.company}</p>
              <p>Full Name: {selectedCustomerData.fullname}</p>
              <p>Estimated Revenue: {selectedCustomerData.estimated_revenue}</p>
              <p>Email: {selectedCustomerData.email}</p>
            </>
          ) : (
            <p>Customer Information</p>
          )}
        </ExpansionPanel>
      </Sidebar>
      <Spinner show={showSpinner} />
    </MainContainer>
  );
};
